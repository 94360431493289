
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from '../../../config';
import Axios from 'axios';
import { authHeader } from '../../../services/auth'
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

@Component({ components: { PerfectScrollbar} })
export default class AuditTrails extends Vue{
    public audits: any = [];
    public skip = 0;
    public showLoader = false;
    public curentDataLength = 0;

    public async getAuditTrails(mountCall) {
          mountCall
      ? (this.$store.state.tmpcoLoader = true)
      : (this.showLoader = true);
        try {
            this.skip = mountCall ? 0 : this.skip + 15;
            const response = await Axios.get(`${BASE_API_URL}developer/getAuditTrails`,{ params:{
            id:this.$route.query.id,
            skip: this.skip,
            limit: 15},
            headers: authHeader() });
            if (response.status === 201) {
                if(mountCall){
                    this.audits = response.data?response.data:{audits:[]};
                    this.curentDataLength=response.data.audits.length
                }
                else{
                    this.audits=response.data?this.audits.concat(response.data):this.audits
                    this.curentDataLength=response.data.audits.length
                }
            }
        } catch (error) {
        console.log(error);
        }
         mountCall
      ? (this.$store.state.tmpcoLoader = false)
      : (this.showLoader = false);
    }

  public async scrollHandle(evt) {
    const scroll: any = this.$refs.scrollbar;
    if (
      !this.showLoader &&
      scroll.ps.reach.y == "end" &&
    this.curentDataLength===15
    ) {
      await this.getAuditTrails(false);
    }
  }


    mounted() {
        this.getAuditTrails(true);
    }

}
